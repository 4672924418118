.neo-page-wrapper {
  &.neo-page-news {
    .neo-base {
      &-text-wrapper {
        text-align: left;
        width: 85%;
        margin: 3% auto;
      }
    }
  }
}

