@use "sass:math";

@import "../partials/_neo.partial.variables";

body {
  margin: 0;
}

.neo {


  /*** Modal Dialog Info Button ***/
  &-vvg-dialog {
    width: 50%;
  }

  &-site-wrapper {
    width: $neo-width;
    max-width: $neo-site-wrapper-max-width;
    margin: 0 auto;
    border-left: 1px solid $neo-site-outline-color;
    border-right: 1px solid $neo-site-outline-color;
    overflow: hidden;

    a {
      text-decoration: none;
    }
  }

  &-container {
    &-secondray {
      background-color: $neo-color-grey-secondary;
    }

    /**** Neo Buttons Search ****/
    &-btn {
      &-input {
        margin: 10px 10px 10px 0;
        width: unset;
      }
    }
  }
}

/* desktop and large tablets */
@media only screen and (min-width: ($neo-tablet-max + 1)) {
  .neo {
    &-header {
      max-height: $neo-header-height-l;

      &-logo {
        &-wrapper {
          max-width: $neo-logo-width-l;
        }
      }
    }

    &-message-content {
      padding-top: 120px;
    }

    &-footer {
      &-address {
        white-space: pre;
      }
    }

    &-content {
      &-wrapper {
        margin: 0 auto;
        padding: 5%;
      }
    }

    &-site-wrapper {
      .neo-vgr-wrapper {
        padding: 0;
      }
    }

    &-vgr-wrapper {
      padding: 5%;
    }

    /** Components **/
    /**** Menu ****/
    &-menu-main {
      margin-left: 5%;
      z-index: 10;
    }

    &-menu-main-overlay {
      background-color: rgba(0, 0, 0, 0.4);

      /* Black w/opacity */
      &-content {
        left: auto;
        right: auto;
        width: $neo-header-menu-main-overlay-width-l;
        top: $neo-header-height-l;

        &-wrapper {
          color: $neo-color-primary;
          padding: 4% 5%;
        }

        &-column {
          border-right: 1px solid $neo-color-grey-secondary;
          padding: 0 5%;

          &:first-child {
            padding: 0 5% 0 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    /**** base page ****/
    &-base {
      &-picture {

        &-wrapper {
          height: math($neo-desktop-max, 2.5);
        }

        &-text {
          padding: 0 0 20% 8%;
          font-size: 2.8rem;
        }


      }
    }

    /****home base page ****/
    &-home-base {
      &-picture {
        &-wrapper {
          height: math($neo-desktop-max, 2.5);
        }

        &-text {
          width: 35%;
          font-size: 2.8rem;
        }
      }

      .picture-text-content {
        text-shadow: 0 0 black;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 0px;
      }
    }

    /**** Side by side ****/
    &-sidebyside {
      &-image-wrapper {
        min-height: 400px;
      }
    }

    /**** Person Search ****/
    &-container {
      &-rounded {
        border-radius: $neo-border-radius-m $neo-border-radius-m $neo-border-radius-xxl $neo-border-radius-m;
      }
    }

    &-recommendation-info-position {
      position: absolute;
      top: 25%;
    }

    /**** Neo Buttons Search ****/
    &-btn {
      &-input {
        margin: 10px 10px 10px 0;
        width: auto;

        &-group {
          width: auto;
        }

        &-longtext {
          width: 200px;
          min-width: 200px;
        }
      }
    }

    /**** vsr ****/
    &-vsr {

      &-grid-inner-layout {
        width: 100%;

      }

      &-schritt3 {
        border: 1px solid #d9dadb;
        border-left: 5px solid #7DCBB8;
        border-top: 0px;
        padding: 20px 30px;
        border-radius: 0px 0px 4px 4px;
      }

      &-barchart {
        overflow: block;
      }

      &-doughnutchart {
        overflow: hidden;

        &-grid {
          justify-content: center;
          border-top: 0px;
        }
      }

      &-doughnut-chart-canvas {
        height: 200px;
      }

      &-doughnut-chart-des {
        margin: 16px 0px;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        display: flex;
        align-content: center;
        justify-content: center;
        color: #00254d;
        line-height: 20px;
      }

      &-chart-des {
        color: #00254d;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        border-right: 1px solid #D9DADB;
        border-left: 5px solid #7DCBB8;
        padding: 12px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        align-content: center;
        text-align: center;
      }

      &-doughnut-chart-header-des {
        color: #00254d;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        // border-right: 1px solid #D9DADB;
        // border-left: 5px solid #7DCBB8;
        padding: 12px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        align-content: center;
        text-align: center;
      }


      &-doughnut-chart-tr {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }

      &-doughnut-chart-td {
        vertical-align: bottom;
      }

      &-showtwobuttons {
        &-toggle {

          &-txt {
            margin: 3px;
            margin-right: 15px;
          }

          &-click {
            outline: none;
            background: white;
            border: none;
          }

          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-around;
          flex-wrap: wrap;
          flex-direction: row;
        }
      }

      &-content-step {
        border: 1px solid var(--unnamed-color-d9dadb);
        border: 1px solid #D9DADB;
        border-radius: 0px 0px 4px 4px;
        border-top: 0px;
        opacity: 1;
        border-left: 5px solid #7DCBB8;
        padding: 0px 30px;
      }

      &-kinder-alter {
        width: 100%;

        &-td {
          width: 100%;
          display: flex;
        }
      }

      &-partner-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
      }

      &-child-table {
        display: flex;

        padding: 10px 0px;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        align-items: flex-start;

        &-trow {
          padding-right: 10px;
          padding-bottom: 20px;

        }


      }

      &-alter {
        width: 100%;
        padding: 19px 10px 0px 0px;

      }

      &-familienstand {
        width: 100%;
        padding: 19px 14px 0px 10px;


      }

      &-schritt-partner {
        display: flex;
        width: 100%;
        flex-direction: row;
      }

      &-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-td {
          top: 454px;
          left: 640px;
          height: 16px;
          text-align: left;
          letter-spacing: 0px;
          color: #02254D;
          opacity: 1;
        }

        &-add {
          align-self: center;
          width: 25%;
          padding-right: 10px;
          height: 93px;
          display: flex;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
          min-width: 195px;
          max-width: 195px;

        }
      }

      &-partner {

        padding: 10px;
        padding-top: 20px;

        &-alter {
          display: flex;
          height: 50px;
          padding: 10px 0px;
          align-items: flex-end;
        }
      }

      &-jahresinkommen {
        width: 100%;
      }

      &-jahreseinkommen-title {
        &-label {
          width: 250px
        }

        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        justify-content: space-between;
        padding:0px 0px 10px 0px;
      }

      &-schritt1 {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        flex-direction: row;
        align-items: stretch;
      }

    }


    &-vsr-flex {
      display: flex;
    }





    &-vsr-right::-webkit-scrollbar {
      width: 0;
    }


    &-recommendation-info {
      font-size: 21px;
      vertical-align: bottom;
      padding-left: 8px;
      top: unset;
      cursor: pointer;
    }

    &-vsr-dropdown-select-trigger {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-vsr-kinder {

      margin-top: 8px;

      &-table {
        display: flex;
        justify-content: flex-start;

        &-trow {
          display: flex;
          flex-direction: column;
          align-content: center;
        }
      }

      &-text {
        top: 421px;
        left: 640px;
        width: 63px;
        height: 20px;
        text-align: left;
        font: normal normal bold 20px/17px Univia Pro;
        letter-spacing: 0px;
        color: #00254D;
        opacity: 1;
      }
    }




    &-vsr-left {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      padding: 90px 0px 0px 78px;

      &-title {
        word-break: break-word;
        left: 73px;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-cbbb9e);
        text-align: left;
        letter-spacing: 0px;
        color: #7DCBB8;
        opacity: 1;
        font-family: 'UniviaPro-Regular';
        font-size: 48px;
        font-weight: 700;
      }

      &-subtitle {
        font-size: 16px;
        font-family: 'UniviaPro-Regular';
        height: auto;
        text-align: left;
        letter-spacing: 0.3px;
        color: #00254D;
        opacity: 1;
      }

      &-description {
        font-size: 16px;
        font-family: 'UniviaPro-Regular';
        height: auto;
        text-align: left;
        letter-spacing: 0.3px;
        color: #00254D;
        opacity: 1;
      }
    }


    /**** vgr ****/
    /**** tariff list description ****/
    &-tariff-list-description {
      &-header {
        text-align: left;
      }
    }

    /*** sticky top position ***/
    &-tariff-detail-element-sticky-element {
      top: $neo-header-height-l !important;
    }

    /*** stepper ***/
    &-stepper {
      .mat-horizontal-stepper {
        &-header {
          &-container {
            width: 70%;
          }
        }
      }
    }

    /*** medium search ***/
    &-search {
      &-medium {
        &-toggle {
          top: $neo-header-height-l + 25px;
        }

        &-wrapper {
          top: $neo-header-height-l + 25px;
          left: -301px;
        }
      }
    }

    /*** small search init height ***/
    &-x-entry-search-content-outer {
      height: auto;
    }

    &-footer-left {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: baseline;
    }

    /** neo about section **/
    &-about {
      &-div {
        background-color: #F0F9FC;
        height: auto;
        width: auto;
        padding: 74px;
      }

      &-headings {
        display: flex;
        align-content: flex-start;
        justify-content: space-between;
        margin-top: 10px;
        width: 50%;
        margin-left: 6%;
        max-width: 450px;
      }

      &-paragraphs {
        display: flex;
        align-content: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-left: 6%;
        font-weight: 100;
      }
    }


    /*** neo-news ***/

    &-news {
      padding: 0px;

      &-content {

        &-flex {
          display: flex;
          padding: 76px 0px 0px 0px;
        }
      }

      &-left-content {
        align-self: center;
        word-break: keep-all;

        &-img {
          height: 308px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;

        }
      }

      &-img {
        width: 100%;
        height: 100%;
        opacity: 1;
        border-radius: 0px 0px 20px 0px;
      }

      &-img-mehr-info {
        padding-left: 10px;
        padding-top: 5px;
        display: none;
      }

      &-link-mehr-info {
        display: flex;
        font-weight: 500;
        opacity: 0.7;
        width: max-content;
      }

      &-link-mehr-info:hover {
        color: #00254d;
        cursor: pointer;

        .neo-news-img-mehr-info {
          display: block;
          cursor: pointer;
        }
      }

      &-text-aktuelles {
        align-self: center;
        text-align: -webkit-right;
        padding-bottom: 476px;
        padding-right: 169px;
        font-size: 27px;
      }

      &-aktuelles-thema {
        display: inline-grid;
        font-size: 44px;
        font-weight: 600;
      }
    }

    &-mehr-news {
      letter-spacing: 0px;
      width: max-content;
      color: #70c9e6;
      opacity: 1;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      cursor: pointer;

      margin-bottom: 30px;

      &-link {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        opacity: 0.8;
      }

      &-link:hover {
        width: 100%;
        display: flex;
        color: #00254d;
      }
    }

    &-mehr-news:hover {
      cursor: pointer;
      color: #00254d;
    }

    &-mehr-news:hover {
      .neo-news-img-mehr-info {
        display: block;
        cursor: pointer;
      }
    }
  }

  .neo-menu-x-button {
    margin-left: 24px;
    cursor: pointer;
    margin-top: 15px;
  }

  .delang {
    margin-left: 150px;
  }

  .neo-number {
    margin: 8px;
  }

  .neo-phone-h3 {
    font-size: 1.1rem;
  }

  .menu-content-padding-top {
    padding-top: 0;
  }

  .mdc-layout-grid__cell.neo-vsr-right {
    overflow-y: scroll;
    width: 100%;
    padding: 75px 0px 0px 0px;
  }


  .smallScreen {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
  }

  .neo-div-cont .neo-footer-left {
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: baseline;
  }

  .neo-footer-wrapper {
    margin-top: 0px;
    color: white;
  }

  .neo-footer-left .left-text {
    padding: 25px 0px 15px 0px;
    line-height: 30px;
    align-self: flex-start;
  }

  .neo-vsr-partner-grid .neo-vsr-schritt-partner .neo-vsr-partner {
    width: 100%;
  }

  .neo-vsr-flex .mdc-layout-grid .neo-vsr-grid-layout {
    padding: 0px;
  }

  .neo-vsr-content-step .neo-vsr-schritt1 .neo-vsr-alter {
    width: 100%;
  }

  .neo-vsr-flex .mdc-layout-grid .neo-vsr-grid-kinder {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .neo-weniger-details {
    width: 50%;

  }

  .neo-vsr-doughnutchart .mdc-layout-grid .neo-vsr-doughnutchart-grid {
    display: flex;
  }

  .smallScreen .neo-footer-background {
    padding: 10px 0px;
  }

  .neo-footer-left .neo-footer-agentur-finden {
    padding: 0px 17px;
  }

  //  .neo-div-cont .neo-footer-jumbotron-list-div-main{
  //    padding-bottom: 0px;
  //  }

  /*** footer ***/


  // &-div-cont{
  //   display: flex;
  //   justify-content: space-around; 
  //   padding: 30px;
  // }

  // &-footer-agentur-finden{
  //   margin: 15px 0px;
  //   width: auto;
  //   padding: 0px 17px;
  // }

  .neo-div-cont {
    padding: 30px;
  }


  .mdc-layout-grid.neo-vsr-grid.neo-vsr-left-bg {
    padding-left: 0px;
    height: 100%;

  }

  .neo-footer-left {
    height: 100%;
    width: auto;
  }

  .neo-vsr-showtwobuttons-toggle {
    display: flex;
    justify-content: flex-end;
  }

  .neo-vsr-bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 48px;
  }

  .neo-footer-no-padding {
    width: 100%;
  }

  .neo-footer-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    align-items: baseline;
  }

  .neo-vsr-right-contact .neo-vgr-wrapper {
    padding: 0px;
  }

  .neo-vsr-right-contact .neo-vsr-right-contact-grid {
    padding: 0px;
  }

  .neo-vsr-right-contact-grid form .mdc-layout-grid__inner .neo-vgr-contact-form h2 {
    margin: 20px 0px;
  }

  .neo-vsr-right-contact-grid .mdc-layout-grid {
    padding: 0px;
  }

  .neo-vsr-bg {
    z-index: -1;
    position: absolute;
    height: 85%;
  }

  //left-side-sticky
  .neo-vsr-container {
    display: block;
    cursor: default;
    position: relative;
    padding-left: 40px;
    margin-bottom: 12px;
    font-size: 18px;
    padding: 24px 24px 24px 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  .neo-vsr-main-left-side {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 29%;
    padding-left: 15px;
  }

  .neo-vsr-left-side-height {
    flex-basis: 50%;
    flex-grow: 1;
    height: 100%;
  }

  .neo-vsr-content-img {
    width: 100%;
    position: sticky;
    background-color: #fff;
    padding: 44px 0px;
    position: -webkit-sticky;
    top: 0;
  }

  .neo-vsr-display-flex {
    display: flex;
  }

  .neo-vsr-span-content {
    line-height: 24px;
    font-size: 16px;
    font-family: 'UniviaPro-Regular';
  }

  .neo-vsr-right {
    width: 70%;
    padding-left: 15px;
    margin: auto 0 auto auto;
  }

  .neo-vsr-grid-kinder .neo-vsr-kinder .neo-vsr-child-table-trow {
    width: 25%;
    min-width: 195px;
    max-width: 195px;
  }




  .mat-expansion-panel-header-title .neo-footer-click-link {
    margin: 0px;
  }

  .pramieBerechnen-btn {
    cursor: pointer;
    background: #00254D;
    color: white;
    width: 100%;
    height: 45px;
    line-height: 38px;
    padding: 1px 10px;
    border: none;
    border-radius: 4px 4px 12px 4px;
  }

  .neo_vsr_accordion {
    font-size: 20px;
    font-family: 'UniviaPro-Regular';
    background-image: url(/assets/images/down.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    background-color: white;
    color: #00254d;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    padding: 12px 24px 12px 30px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    opacity: 1;
    height: 80px;
    font-family: UniviaPro-regular;
  }

  .neo_vsr_accordion_active {
    background-image: url(/assets/images/up.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    padding-right: 30px;
    color: #00254d;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    padding: 12px 24px 12px 24px;
    border: 1px solid #D9DADB;
    border-left: 5px solid #7DCBB8;
    border-bottom: 0px;
    border-radius: 4px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    font-family: UniviaPro-Bold;
  }

  .neo_vsr_accordion_schritt4_active {
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    padding-right: 30px;
    color: #00254d;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    padding: 12px 24px 12px 24px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    font-family: UniviaPro-Bold;
  }

  .neo_vsr_accordion_schritt4 {
    font-size: 20px;
    font-family: 'UniviaPro-Regular';
    background-color: red;
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    background-color: white;
    color: #00254d;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    padding: 12px 24px 12px 30px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    opacity: 1;
    height: 80px;
    font-family: UniviaPro-regular;

  }


}