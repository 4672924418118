/* You can add global styles to this file, and also import other style files */
@use '../node_modules/@angular/material' as mat;
@import url("https://api.fontshare.com/css?f[]=satoshi@400,500,700&display=swap");
@import '../node_modules/@angular/cdk/overlay-prebuilt.css';
@import "../node_modules/ngx-spinner/animations/ball-spin-clockwise.css";


$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.core();
@include mat.datepicker-theme($my-theme);

@font-face {
  font-family: 'Univia Pro Light';
  src: url('../src/assets/fonts/UniviaPro-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Univia Pro Regular';
  src: url('../src/assets/fonts/UniviaPro-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Univia Pro Bold';
  src: url('../src/assets/fonts/UniviaPro-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../src/assets/fonts/CalibreRegular.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Light';
  src: url('../src/assets/fonts/Calibre-Light.ttf') format('truetype');
  font-style: normal;
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-light-blue: #F4F4FA;
  --color-sky: #5BC9E9;
  --color-menu: #C2B59B99;
  --color-dark-blue: #2F3F60;

}

.font-calibre-regular-20 {
  font-family: 'Calibre', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;

}

.font-calibre-20 {
  font-family: 'Calibre', sans-serif;
  font-size: clamp(14px, 1.5vw, 20px);
  font-style: normal;
  font-weight: 600;
  line-height: clamp(16px, 3vw, 20px);
}

.font-calibre-18 {
  font-family: 'Calibre', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.font-calibre-70 {
  font-family: 'Calibre', sans-serif;
  font-size: clamp(50px, 4vw, 70px);
  font-weight: clamp(400, 500, 700);
  line-height: clamp(50px, 5vw, 70px);
  letter-spacing: 0em;
}

.font-univia-pro-regular-14 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.font-univia-pro-14 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 7px;
}

.font-univia-pro-16 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 100% */
}

.font-univia-pro-17 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.font-univia-pro-18 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 400;
  line-height: clamp(24px, 3vw, 28px);
  letter-spacing: 0em;
}

.font-univia-pro-20 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: clamp(14px, 1.5vw, 20px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.font-univia-pro-23 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: clamp(20px, 2vw, 23px);
  line-height: clamp(30px, 5vw, 36px);
  letter-spacing: 0em;
}

.font-univia-pro-30 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: clamp(22px, 2vw, 30px);
  font-weight: 400;
  line-height: clamp(30px, 7vw, 38px);
  letter-spacing: 0em;
}

.font-univia-pro-59 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: 59px;
  font-weight: 400;
  line-height: 74px;
  letter-spacing: 0em;

}

.font-univia-pro-70 {
  font-family: 'Univia Pro Regular', sans-serif;
  font-size: clamp(45px, 4vw, 70px);
  font-size: 55px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: 0em;
}

.font-helvetica-16 {
  font-family: helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: 16px;
  text-transform: uppercase;
}

.text-align-left {
  text-align: left;

}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

html,
body {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  margin: 0;
}


img,
video {
  max-width: 100%;
  height: auto;
}

.opacity-05 {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0px;
}

.top-0 {
  top: 0px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-10 {
  gap: 10px;
}

.gap-40 {
  gap: 2.5rem;
}

.gap-50 {
  gap: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible {
  background-color: #fff;
}

.mat-mdc-option.mdc-list-item .mdc-list-item__primary-text {
  font-size: 14px;
  color: #777777;
  font-family: 'Calibre', sans-serif;
}

.mat-mdc-select-panel.mdc-menu-surface {
  background-color: white;
}

.mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value {
  padding-top: 4px;
}

.mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  display: none;
}

.mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  background-image: url('../src/assets/icons/arrowDown.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex {
  align-items: center;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  height: 50px;
  align-items: center;
}

.mat-mdc-text-field-wrapper.mdc-text-field .mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}

.mat-mdc-form-field-infix .mdc-floating-label.mat-mdc-floating-label {
  padding-top: 5px;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  padding-top: 5px;
}

.mat-calendar {
  background-color: white;
}

@media (max-width: '880px') {
  .font-univia-pro-59 {
    // font-family: Univia Pro;
    font-size: 30px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    letter-spacing: 0em !important;

  }

  .font-calibre-70 {
    font-family: 'Calibre', sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;

  }
}

.pointer {
  cursor: pointer;
}

.modal-content {
  background-color: var(--bg-color);
}

.font-14 {
  font-size: 14px;
}

// hypothekenrechner calculator global styles

@font-face {
  font-family: UniviaPro-Book;
  src: url("../node_modules/hypothekenrechner-lib/assets/Fonts/UniviaPro-Book.otf") format("truetype");
}

@font-face {
  font-family: UniviaPro-Regular;
  src: url("../node_modules/hypothekenrechner-lib/assets/Fonts/UniviaPro-Regular.otf") format("truetype");
}

@font-face {
  font-family: UniviaPro-Bold;
  src: url("../node_modules/hypothekenrechner-lib/assets/Fonts/UniviaPro-Bold.otf") format("truetype");
}

@font-face {
  font-family: UniviaPro-Medium;
  src: url("/hypothekenrechner-library/src/assets/Fonts/UniviaPro-Medium.otf") format("truetype");
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.cdk-overlay-pane {
  border-bottom-right-radius: 100px;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

//neo-markdown
.neo-markdown strong {
  font-size: 16px;
  font-family: UniviaPro-Regular;
}

.neo-markdown p {
  font-size: 18px;
  width: 100%;
  text-align: left;
  font-family: Calibre;
  letter-spacing: 0px;
  color: #00254D;
  opacity: 1;
  word-wrap: break-word;
  line-height: 26px;
  display: flex;
}

.neo-vsr-container .neo-markdown p {
  display: block;
}

.neo-markdown p .neo-markdown-btn {
  color: #fff;
  font-size: 16px;
  background-color: #00254d;
  padding: 8px;
  border-radius: 2px 2px 12px 2px;
  font-family: 'UniviaPro-Regular';
}

.neo-markdown .neo-markdown-btn {
  color: #fff;
  font-size: 16px;
  background-color: #00254d;
  padding: 8px;
  border-radius: 2px 2px 12px 2px;
  font-family: 'UniviaPro-Regular';
}

.neo-markdown p a {
  font-size: 18px;
  font-family: Calibri;
  color: #70c9e6;
}

.neo-markdown a {
  font-size: 18px;
  font-family: Calibri;
  color: #70c9e6;
}

.neo-markdown ol li {
  font-size: 18px;
  line-height: 26px;
  word-break: break-word;
  font-family: Calibri;
}

.neo-markdown ol a {
  font-size: 18px;
  font-family: Calibri;
  color: #70c9e6;
}

.neo-markdown ul li {
  font-size: 18px;
  line-height: 26px;
  word-break: break-word;
  font-family: Calibri;
}

.neo-markdown ul a {
  font-size: 18px;
  font-family: Calibri;
  color: #70c9e6;
}

.neo-markdown img {
  margin-right: 10px;
  margin-bottom: 10px;
}

.neo-vvg-dialog {
  position: absolute !important;

  .mat-mdc-dialog-container {
    color: #002B4E;
    font-family: UniviaPro-Regular, Arial, sans-serif;
    font-size: 16px;

    .neo-vvg-dialog-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    border-bottom-right-radius: 8px;
    background-color: #D9DADB;
  }

  .neo-dialog-title-text {
    // font-family: UniviaPro-Bold;
    font-size: 16px;
  }

  .neo-dialog-text {
    // font-family: UniviaPro-Regular;
    font-size: 14px;
    line-height: 22px;
  }

}

.neo-hypothekenrechner-img-sticky {
  display: none;

}


.mat-mdc-select.neo-vsr-dropdown .mat-mdc-select-value-text .neo-vsr-dropdown-select-trigger {
  line-height: 2.6rem;
}

.mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
  padding: 0;
  border: none;
  height: 50px;
  background-color: transparent;
}

.neo-vsr-container .neo-weniger-details-header .mat-mdc-form-field-infix .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, .8)
}


.ng-trigger.ng-trigger-transformPanel.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mdc-list-item__primary-text {
  color: #00254D;

}

.mat-mdc-form-field.neo-vsr-mat-form-field.mat-mdc-form-field-type-mat-select {
  width: fit-content;
}

.mat-mdc-select-min-line {
  margin-right: 5px;
}

.mdc-line-ripple.mdc-line-ripple--deactivating {
  display: none;
}

.mdc-line-ripple.mdc-line-ripple--active {
  display: none;
}

.mat-mdc-autocomplete-trigger.mat-mdc-input-element.neo-vgr-input-field.neo-vgr-input-field-text.neo-vgr-input-margin.neo-helper-user-select-auto.mat-mdc-form-field-input-control.mdc-text-field__input {
  box-sizing: border-box;
  padding: 1px 5px;
  background: #f7f7f7;
  border: none;
  min-height: 50px;
  border-radius: 2px 2px 12px 2px;
  min-width: 150px;
  outline: none;
}

.mat-mdc-form-field-focus-overlay {
  display: none;
}

.mat-mdc-slide-toggle.neo-charts-toggle-button.mat-primary.mat-mdc-slide-toggle-checked {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #00254D;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #00254d;
  --mdc-switch-selected-hover-handle-color: #00254d;
  --mdc-switch-selected-pressed-handle-color: #00254d;
  --mdc-switch-selected-focus-track-color: #bfc8d2;
  --mdc-switch-selected-hover-track-color: #bfc8d2;
  --mdc-switch-selected-pressed-track-color: #bfc8d2;
  --mdc-switch-selected-track-color: #bfc8d2;
}

.mat-mdc-slide-toggle.neo-charts-toggle-button.mat-primary {
  --mdc-switch-disabled-selected-handle-color: #70c9e6;
  --mdc-switch-disabled-unselected-handle-color: #70c9e6;
  --mdc-switch-disabled-selected-track-color: #b0ebfe;
  --mdc-switch-disabled-unselected-track-color: #b0ebfe;
  --mdc-switch-unselected-focus-state-layer-color: #70c9e6;
  --mdc-switch-unselected-pressed-state-layer-color: #70c9e6;
  --mdc-switch-unselected-hover-state-layer-color: #70c9e6;
  --mdc-switch-unselected-focus-track-color: #b0ebfe;
  --mdc-switch-unselected-hover-track-color: #b0ebfe;
  --mdc-switch-unselected-pressed-track-color: #b0ebfe;
  --mdc-switch-unselected-track-color: #b0ebfe;
  --mdc-switch-unselected-focus-handle-color: #70c9e6;
  --mdc-switch-unselected-hover-handle-color: #70c9e6;
  --mdc-switch-unselected-pressed-handle-color: #70c9e6;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #70c9e6;
}

.mdc-switch__icon.mdc-switch__icon--on,
.mdc-switch__icon.mdc-switch__icon--off {
  display: none;
}

.mat-mdc-form-field.neo-input-autocompleter-component {
  margin-top: 10px;
}

.neo-input-time-dropdowns {
  display: flex;
}

.mat-mdc-radio-group.neo-vgr-input-margin {
  display: flex;
  align-items: flex-start;
}

.neo-input-time-dropdowns-hour {
  width: 100%;
}

.neo-input-time-dropdowns-minutes {
  width: 100%;
}

.opacity-0 {
  opacity: 0.3 !important;
}

@media only screen and (max-width: 958px) {
  .neo-hypothekenrechner-intro {
    padding-top: 20px;
  }
}

.swiper-bmi {
  display: none;
}

@media(min-width: '2100px') {
  .font-univia-pro-70 {
    font-size: 135px;
    line-height: 150px;
  }

  .mat-mdc-option.mdc-list-item .mdc-list-item__primary-text {
    font-size: 1vw;
  }
}