@import "../partials/neo.partial.main";
@import "@material/layout-grid/mdc-layout-grid";


// NEO VGR starts here
/// need to be revised START !!!
neo-input-radio-group {  
  mat-radio-group {
    .mat-radio-label {
      white-space: initial;
    }
    .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: $neo-color-secondary;
    }
    .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: $neo-color-secondary;
    }
    .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
      background-color: $neo-color-secondary;
    }
  }
}

neo-input-checkbox{
  .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $neo-color-secondary ;
  }
  .mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: $neo-color-secondary;
  }
  .mat-mdc-checkbox{
    .mat-checkbox-layout{
      white-space: initial;
    }
    .mat-checkbox-frame {
      border-bottom-right-radius: $neo-border-radius-m;
    }
    .mat-checkbox-background, .mat-checkbox-frame {
      border-bottom-right-radius: $neo-border-radius-m;
    }
  }
}

.neo-vvg-dialog {
  position: absolute !important;
  .mat-mdc-dialog-container {
    .neo-vvg-dialog-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    border-bottom-right-radius: $neo-border-radius-m;
    background-color: $neo-color-grey-primary;
  }
}

/// need to be revised END !!!
