@import "../partials/neo.partial.main";
// stepper
$neo-stepper-color-primary: $neo-color-secondary;
$neo-stepper-color-secondary: $neo-color-fg;
.neo {
  &-stepper {
    .mat-horizontal-stepper {
      &-header {
        padding: 0;

        &-container {
          margin: 0 auto;
        }

        &:hover {
          background-color: transparent;
        }

        .mat-step {
          &-icon, &-icon-not-touched {
            margin: 0;
          }
        }
      }
    }

    .mat-horizontal-content-container {
      padding: 0;
    }

    .mat-stepper-horizontal-line {
      border-top: 1px solid $neo-stepper-color-primary;
      margin: 0;
    }

    .mat-step {
      &-icon, &-icon-not-touched {
        height: 48px;
        width: 48px;
      }

      &-label {
        display: none;
      }
    }
    
      .mat-horizontal-stepper-header {
       .mat-step-icon {
        border: 2px solid $neo-stepper-color-primary;
        background-color: $neo-stepper-color-secondary;
        span {
          color: $neo-stepper-color-primary;
           font-weight: 700;
         }
        &-selected, &-state-done, &-state-edit {
          border: 2px solid $neo-stepper-color-primary;
          background-color:$neo-stepper-color-primary;
          span {
            color: $neo-stepper-color-secondary;
            font-weight: 700;
          }
        }
      }
    }
    .mat-step-header.cdk-keyboard-focused,.mat-step-header.cdk-program-focused, .mat-step-header:hover {
      background-color: $neo-color-fg;
    }
    .mat-step-header, .mat-ripple {
      overflow: visible;
    }
  }
}

