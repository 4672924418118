.menu-expansion-panel .mat-expansion-panel-body{
    padding: 0;
}



@import "../partials/neo.partial.variables";
/* tablet and large phones */
@media only screen and (max-width: $neo-tablet-max) {
    .menu-expansion-panel .mat-expansion-panel-header{
        padding: 0px 5px;
    }
}

@media only screen and (min-width: ($neo-tablet-max + 1)) {
    .menu-expansion-panel .mat-expansion-panel-header{
        padding: 0px 24px;
    }
}
@media (max-width: 1124px) and (min-width: 599px) {
    .menu-expansion-panel .mat-expansion-panel-header{
        padding: 0px ;
    }
    .neo-footer-background .neo-footer-click-links{
        padding: 5px;
    }
    .menu-expansion-panel .mat-expansion-panel-body{
        padding: 0px;
}
}