.swiper-el {
  margin-top: 50px;

  .swiper.s-wrapper {
    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }
  }

}

.swiper-pagination {
  margin-bottom: -10px;
}

.bmi-tools-swiper {
  overflow: hidden;
  min-width: 200px;
}

.neo-grid-flex .bmi-tools-swiper .neo-grid-component {
  max-width: none;
  min-height: 205px;
}

.neo-grid-flex .bmi-tools-swiper .neo-grid-component .neo-grid-component-wrapper-icon {
  display: none;
}


@media (min-width: 1204px) {
  .swiper-el .s-wrapper .swiper-pagination {
    display: none;
  }
}

@media screen and (max-width: 959px) and (min-width: 842px) {
  .swiper-el .s-wrapper .swiper-pagination {
    display: none;
  }
}

.neo-grid-component {
  mat-icon {
    display: none;
  }

  &--color-krankenkassenvergleich {
    max-width: none;
    border: 1px solid #69C0BB;
    border-radius: 4px 4px 24px 4px;
    background-color: #69C0BB;

    h3,
    p {
      color: white
    }

    overflow: hidden;

    mat-icon {
      display: none;
    }

  }

  &--color-vorsorgerechner {
    border: 1px solid #7DCBB8;
    border-radius: 4px 4px 24px 4px;
    background-color: #7DCBB8;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }

  }

  &--color-bmi {
    border: 1px solid #A781A9;
    border-radius: 4px 4px 24px 4px;
    background-color: #A781A9;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }
  }

  &--color-hypothekenrechner {
    border: 1px solid #BCD056;
    border-radius: 4px 4px 24px 4px;
    background-color: #BCD056;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }

  }

  &--color-steuerrechner {
    border: 1px solid #F9B200;
    border-radius: 4px 4px 24px 4px;

    h3,
    p {
      color: white
    }

    background-color: #F9B200;

    mat-icon {
      display: none;
    }

  }

  &--color-risikomap {
    border: 1px solid #EB6A31;
    border-radius: 4px 4px 24px 4px;
    background-color: #EB6A31;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }

  }
}