.neo-cms-sidebyside {
  overflow: hidden;

  ul,li {
    color: white;
  }
  p{
    margin: 10px 0 0 0;
    color: white;
  }
  h3{
      color: white;
  }

  &-wrapper{  
      margin-bottom: 13px;
    }

  &-content, &-image {
    &-wrapper {
    }
  }

  &-content-wrapper {
    padding: 5%;
  }

  &-image-wrapper {
    overflow: hidden;
    height: 100%;
    margin-top: 0px; // only for IE 11
    margin-bottom: 0; // only for IE 11

    .neo-cms-sidebyside-image {
      width: 100%;
      min-height: 100%;
      height: 100%; /*for IE*/
      display: block;
      background-position: 0 0;
      background-size: cover;
    }
  }

  &-content {
    &-text {
      * {
        color: white;
      }
    }
  }
}
a {
  color: #70C9E6;
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
}


