/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

@media (max-width: 479px){
  .mdl-cell--3-col, .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 839px) and (min-width: 480px){
  .mdl-cell--3-col, .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    margin-left: auto;
    margin-right: auto;
  }
  .mdl-cell--2-col, .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet{
    margin-right: auto;
    margin-left: auto;
  }
}

@font-face {
  font-family: UniviaPro-Bold;
  src: url("/assets/fonts/UniviaPro-Bold.otf") format("truetype");
}

@font-face {
  font-family: UniviaPro-Medium;
  src: url("/assets/fonts/UniviaPro-Medium.otf") format("truetype");
}
@font-face {
  font-family: UniviaPro-Book;
  src: url("/assets/fonts/UniviaPro-Book.otf") format("truetype");
}
@font-face {
  font-family: UniviaPro-Regular;
  src: url("/assets/fonts/UniviaPro-Regular.otf") format("truetype");
}

.text-padding {
  margin: 16px;
}

.img-responsive {
  width: 100% !important;
}

#collapseTwo {
}

#collapseTwo > div {
}

#collapseThree {
}

#collapseThree > div {
}

.neo-tick {
  float: left;
  width: 100%;
}

.neo-tick > img {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  width: 60px;
}

.neo-tick > img, .neo-tick > p {
  float: left;
  color: whitesmoke;
}

.neo-tick > p {
  margin-top: 20px;
  width:75%;
  font-family: UniviaPro-regular;
  font-size: 17px;
}

.neo-tick-click-me {
  text-align: center;
  margin: 25px;
}

@media screen and (max-width: 1284px) and (min-width: 840px) {
  .neo-tick {
    float: left;
    width: 100%;
  }

  .neo-tick > img {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    width: 60px;
  }

  .neo-tick > img, .neo-tick > p {
    float: left;
  }

  .neo-tick > p {
    margin-top: 23px;
    width: 62%;
    font-family: UniviaPro-regular;
    font-size: 14px;
  }

  .neo-tick-click-me {
  }

  .btn{
    display: inline-block;
    padding: 8px 25px !important;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: UniviaPro-bold;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
@media screen and (max-width: 431px) {
  .neo-tick > p {
    margin-top: 20px;
    width: 70%;
    font-family: UniviaPro-regular;
    font-size: 17px;
  }
  .btn{
    display: inline-block;
    padding: 8px 25px !important;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: UniviaPro-bold;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
@media screen and (max-width: 555px) and (min-width: 451px) {
  .neo-tick > p {
    margin-top: 30px;
  }
}
@media screen and (max-width: 839px) and (min-width: 575px) {
  .neo-tick > img {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    width: 30px;
  }
}
@media screen and (max-width: 1326px) and (min-width: 1286px) {
  .btn{
    display: inline-block;
    padding: 8px 80px !important;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: UniviaPro-bold;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
@media screen and (min-width: 1520px){
  .neo-tick > img {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    width: 60px;
  }
  .neo-tick > p {
    margin-top: 30px;
  }
}
.btn {
  display: inline-block;
  padding: 8px 80px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  font-family: UniviaPro-bold;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.btn-default {
  color: #00254d;
  background-color: #70c9e6;
  border-radius: 4px 4px 12px;
  text-decoration: none;
  margin-top: 25px;
}

.neo-heading {
  line-height: 1.3;
  font-family: UniviaPro-bold;
  padding-left: 35px;
  font-size: 16px;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
  color: whitesmoke;
}

.neo-float-right {
  margin-right: 10px;
  margin-top: 10px;
}

#top-header .neo-bmi-text-section p{
  font-family: UniviaPro-book;
}

input[type=number] {
  -moz-appearance:textfield;
}
