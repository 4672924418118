@import "../partials/neo.partial.variables";
/* phones */
@media only screen and (max-width: $neo-phone-max) {
  .neo {
    &-header {
      max-height: $neo-header-height-s;

      &-logo {
        &-wrapper {
          max-width: $neo-logo-width-s;
        }
      }
    }

    &-message-content {
      padding-top: 30px;
    }

    /** Components **/
    /**** Menu overlay ****/
    &-menu-main-overlay {
      &-content {
        top: $neo-header-height-s;
        width: $neo-header-menu-main-overlay-width-s;
      }
    }

    /**** base page ****/
    &-base {
      &-picture {
        &-text {
          font-size: 1.8rem;
        }
      }
    }

    /**** home base page ****/
    &-home-base {
      &-picture {
        &-text {
          width: 85%;
          justify-self: center;
          text-align: center;
          margin-top: 50px;
        }
      }

      .picture-text-content {
        text-shadow: 0 0 black;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 35px;
      }
    }

    /**** Side by side ****/
    &-sidebyside {
      &-image-wrapper {
        min-height: 300px;
        order: 1;
      }

      &-content-wrapper {
        order: 2;
      }
    }

    /**** Person Search ****/
    &-container {
      &-rounded {
        border-radius: $neo-border-radius-s $neo-border-radius-s $neo-border-radius-xl $neo-border-radius-s;
      }
    }

    &-recommendation-info-position {
      position: absolute;
      top: -23px;
      right: 7px;
    }


    /*** vsr ***/

    &-vsr-grid {
      height: 100%;
      width: 100%;
      padding: 0px;

      &-layout {
        width: 100%;

      }
    }


    &-vsr-inner {
      height: 100%;

      flex-direction: row;
      align-content: flex-start;

    }

    &-vsr-right {

      padding: 0px;
    }


    &-vsr {
      &-child-table {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        &-trow {

          padding: 0px;
        }


      }

      &-schritt3 {
        border: 1px solid #d9dadb;
        border-left: 5px solid #7DCBB8;
        border-top: 0px;
        padding: 20px 16px;
        border-radius: 0px 0px 4px 4px;
      }

      &-doughnut-chart-canvas {
        height: 200px;
      }

      &-doughnut-chart-td {
        vertical-align: bottom;
      }

      &-doughnut-chart-des {
        margin: 16px 0px;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        display: flex;
        align-content: center;
        justify-content: center;
        color: #00254d;
        line-height: 20px;
      }

      &-chart-des {
        color: #00254d;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        border-right: 1px solid #D9DADB;
        border-left: 5px solid #7DCBB8;
        padding: 12px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        align-content: center;
        text-align: center;
      }

      &-doughnut-chart-header-des {
        color: #00254d;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        // border-right: 1px solid #D9DADB;
        // border-left: 5px solid #7DCBB8;
        padding: 12px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        align-content: center;
        text-align: center;
      }

      &-doughnut-chart-tr {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }

      &-doughnutchart {
        overflow: hidden;

        &-grid {

          justify-content: center;
          border-top: 0px;
        }
      }

      &-showtwobuttons {
        &-toggle {
          &-txt {
            margin: 3px;
            margin-right: 15px;
          }

          &-click {
            outline: none;
            background: white;
            border: none;
          }

          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-around;
          flex-wrap: wrap;
          flex-direction: row;
        }

        justify-content: flex-end;
      }

      &-content-step {
        border: 1px solid var(--unnamed-color-d9dadb);
        border: 1px solid #D9DADB;
        border-radius: 0px 0px 4px 4px;
        border-top: 0px;
        opacity: 1;
        border-left: 5px solid #7DCBB8;

      }

      &-grid-kinder {

        width: 100%;
      }

      &-schritt3-mat-card {
        display: flex;
      }

      // &-schritt-partner {
      //   display: flex;
      //   width: 100%;
      //   flex-direction: row;
      // }
      &-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-td {
          top: 454px;
          left: 640px;
          height: 16px;
          text-align: left;
          letter-spacing: 0px;
          color: #02254D;
          opacity: 1;
        }

        &-add {
          align-self: center;
          width: 100%;
          padding: 10px 0px;
          display: flex;
        }
      }

      &-partner {
        width: 100%;
        padding-bottom: 10px;
        padding-top: 20px;

        &-alter {
          display: flex;
          height: 50px;
          padding: 10px 0px;
          align-items: flex-end;
        }
      }

      &-jahresinkommen {
        width: 100%;
      }

      &-jahreseinkommen-title {
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        padding: 0px 0px 10px 0px;

        &-label {
          width: 250px
        }
      }

      &-schritt1 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      &-alter {
        width: 100%;
        padding: 19px 0px 0px 0px;

      }

      &-familienstand {
        width: 100%;
        padding: 19px 0px 0px 0px;

      }
    }


    &-vsr-flex {
      display: flex;
      width: 100%;
    }



    &-vsr-right::-webkit-scrollbar {
      width: 0;
    }


    &-recommendation-info {
      font-size: 21px;
      vertical-align: bottom;
      padding-left: 8px;
      top: unset;
      cursor: pointer;
    }

    &-vsr-dropdown-select-trigger {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-vsr-kinder {

      &-table {
        display: flex;
        justify-content: flex-start;

        &-trow {
          display: flex;
          flex-direction: column;
          align-content: center;
        }
      }

      &-text {
        top: 421px;
        left: 640px;
        width: 63px;
        height: 20px;
        text-align: left;
        font: normal normal bold 20px/17px Univia Pro;
        letter-spacing: 0px;
        color: #00254D;
        opacity: 1;
      }

      &-alter {
        width: 100%;

        &-td {
          width: 100%;
          display: flex;
        }
      }
    }






    &-vsr-left {
      // background: url(../../../assets/vorsorgerechner-assets/img/neo-half-o-vertical-2.svg);
      // background-repeat: no-repeat;
      // background-position-y: center;
      // background-position-x: left;
      // background-size: 143px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      &-title {
        font-size: 32px;
        font-family: 'UniviaPro-Bold';
        height: auto;
        // color: var(--unnamed-color-cbbb9e);
        text-align: left;
        // font: normal normal medium 32px/44px Univia Pro;
        letter-spacing: 0px;
        color: #7DCBB8;
        opacity: 1;
      }

      &-subtitle {
        font-size: 16px;
        font-family: 'UniviaPro-Regular';
        height: auto;
        // letter-spacing: var(--unnamed-character-spacing-0-3);
        text-align: left;
        // font: normal normal normal 20px/31px Univia Pro;
        letter-spacing: 0.3px;
        color: #00254D;
        opacity: 1;
      }

      &-description {
        font-size: 16px;
        font-family: 'UniviaPro-Regular';
        height: auto;
        // letter-spacing: var(--unnamed-character-spacing-0-3);
        text-align: left;
        // font: normal normal normal 20px/31px Univia Pro;
        letter-spacing: 0.3px;
        color: #00254D;
        opacity: 1;
      }
    }



    /**** vgr ****/
    /**** tariff list description ****/
    &-tariff-list-description {
      &-header {
        text-align: center;
      }
    }

    /*** sticky top position ***/
    &-tariff-detail-element-sticky-element {
      top: $neo-header-height-s !important;
    }

    /*** stepper ***/
    &-stepper {
      .mat-horizontal-stepper {
        &-header {
          &-container {
            width: 95%;
          }
        }
      }
    }

    /*** service ***/
    &-service {
      &-icon {

        &-wrapper,
        &-img {
          max-height: 100px;
        }
      }

      &-content {
        max-height: 120px;
      }
    }

    /*** tariff description ***/
    &-tariff-list-description {
      padding: 0 0 5px 0;
      margin: 0 0 5px 0;

      &-header {
        * {
          margin: 0;
        }
      }

      &-content {
        * {
          margin: 0 0 5px 0;
        }
      }
    }

    /**** Neo Buttons ****/
    &-btn {
      &-input {
        margin: 5px 0px 5px 0;
        width: 100%;

        &-group {
          width: 100%;
        }
      }
    }

    /*** Modal Dialog Info Button ***/
    &-vvg-dialog {
      width: 100%;
    }

    /*** medium search ***/
    &-search {
      &-medium {
        &-toggle {
          top: $neo-header-height-s + 255px;
        }

        &-wrapper {
          top: $neo-header-height-s + 25px;
          left: -300px;

        }
      }
    }

    /*** small search init height ***/


    /** neo about section **/
    &-about {
      &-div {
        background-color: #F0F9FC;
        height: auto;
        width: auto;
        padding: 12px;
      }

      &-headings {
        display: flex;
        padding: 0px 15% 0px 0px;
        align-content: space-around;
        margin-top: -1%;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        max-width: 450px;
      }

      &-paragraphs {
        display: flex;
        align-content: flex-start;
        justify-content: space-between;
        width: 100%;
        font-weight: 100;
      }
    }

    /*** News ***/
    &-news {
      padding: 0px;

      &-content {
        padding: 0px;

      }

      &-aktuelles-thema {
        font-size: 32px;

        &-tablet {
          display: contents;
          width: 100%;
          text-align: right;
        }
      }

      &-img-mehr-info {
        padding-left: 10px;
        padding-top: 5px;
        display: none;
      }

      &-left-content-img {
        height: 308px;
        background-size: cover;
        background-repeat: space;
        background-position: center;
        background-position-y: top;
        padding: 0px;
      }

      &-link-mehr-info {
        display: flex;
        opacity: 0.7;
        font-weight: 500;
        width: max-content;
      }

      &-link-mehr-info:hover {
        color: #00254d;
        cursor: pointer;

        .neo-news-img-mehr-info {
          display: block;
          cursor: pointer;
        }
      }

      &-left-content {
        word-break: keep-all;
      }

      &-img {
        width: 100%;
        height: 308px;
        opacity: 1;
        border-radius: 0px 0px 20px 0px;
      }

      &-img-mehr-info {
        padding-left: 10px;
        display: none;
      }
    }

    &-mehr-news {
      width: 100%;
      padding-left: 0px;
      margin-bottom: 60px;

      &-link {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        opacity: 0.8;
      }

      &-link:hover {
        width: 100%;
        display: flex;
        color: #00254d;
      }
    }

    &-mehr-news:hover {
      cursor: pointer;
      color: #00254d;
    }

    &-mehr-news:hover {
      .neo-news-img-mehr-info {
        display: block;
        cursor: pointer;
      }
    }

  }

  .pdf-page-padding {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 3% !important;
  }

  .mat-expansion-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 6px 0px 24px;
    border-radius: inherit;
  }

  .delang {
    margin-left: 70px;
  }

  .neo-vsr-grid .mdc-layout-grid__inner.neo-vsr-inner {
    display: flex;
  }

  .mdc-layout-grid .mdc-layout-grid__inner.neo-vsr-mat-card {

    display: flex;
    flex-direction: column;
  }

  .neo-number {
    margin: 8px 8px 8px -20px;
  }

  .neo-vsr-doughnutchart .mdc-layout-grid .neo-vsr-doughnutchart-grid {
    display: flex;
    justify-content: center;
    border-top: 0px;
  }

  .neo-vsr-jahreseinkommen .neo-vsr-jahreseinkommen-title {
    width: 100%;
  }

  .neo-vsr-showtwobuttons-toggle {
    display: flex;
    justify-content: flex-end;
  }

  .neo-vsr-grid-kinder {
    display: flex;
  }

  .neo-phone-h3 {
    font-size: 1.1rem;
  }

  .neo-vsr-flex .neo-vsr-grid-layout {
    padding: 0px;
  }

  .neo-vsr-content-step {
    padding: 0px 16px;
  }

  .neo-vsr-left .neo-vsr-ticks .neo-vsr-span-ticks .neo-vsr-ticks-img {
    display: flex;
  }

  .neo-menu-x-button {

    margin-left: 16px;
    cursor: pointer;
    margin-top: 15px;
  }

  .menu-content-padding-top {
    padding-top: 1rem;
  }

  .neo-vsr-grid-kinder .neo-vsr-kinder .neo-vsr-child-table-trow {
    width: 100%;
  }

  .neo-vsr-inner .mdc-layout-grid__cell--span-8-desktop.neo-vsr-right {
    width: 100%;
  }

  .neo-vsr-right-contact .neo-vsr-right-contact-grid {
    padding: 0px;
  }

  .neo-vsr-right-contact-grid form .mdc-layout-grid__inner .neo-vgr-contact-form h2 {
    margin: 20px 0px;
  }

  .neo-vsr-right-contact-grid .mdc-layout-grid {
    padding: 0px;
  }

  .neo_vsr_accordion_schritt4_active {
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    padding-right: 30px;
    color: #00254d;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    padding: 12px 24px 12px 24px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    font-family: UniviaPro-Bold;
  }

  .neo_vsr_accordion_schritt4 {
    font-size: 20px;
    font-family: 'UniviaPro-Regular';
    background-color: red;
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    background-color: white;
    color: #00254d;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    padding: 12px 24px 12px 30px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    opacity: 1;
    height: 80px;
    font-family: UniviaPro-regular;

  }

  .neo-footer-center-text {
    text-align: center;
    padding: 10px;
    margin: 0px
  }

  .neo-footer-wrapper {
    color: white;
  }

  // .neo-footer-jumbotron-list-div-main{ 
  //   width: 100%;
  // }

  .neo-footer-imp {
    width: 100%;
    display: block;
    text-align: left;
  }

  .neo-vsr-bg {
    display: none;
  }

  .neo-footer-agentur-finden {
    width: 100%;
    margin: 15px 0px 0px 0px;
  }

  .neo-footer-left-div {
    width: 100%;
    display: block;

  }

  .neo-weniger-details {
    width: 100%;

  }

  .neo-footer-left {
    padding: 25px 0px 38px 0px;
  }

  .neo-div-cont {
    padding: 16px 16px 38px 16px;
  }

  .mat-expansion-panel-header-title .neo-footer-click-link {
    margin: 0px;
  }

  .pramieBerechnen-btn {
    cursor: pointer;
    background: #00254D;
    color: white;
    width: 100%;
    height: 45px;
    line-height: 38px;
    padding: 1px 10px;
    border: none;
    border-radius: 4px 4px 12px 4px;
  }

  .neo_vsr_accordion {
    font-size: 20px;
    font-family: 'UniviaPro-Regular';
    background-image: url(/assets/vorsorgerechner-assets/img/down.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    background-color: white;
    color: #00254d;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    padding: 12px 16px 12px 20px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    opacity: 1;
    height: 80px;
    font-family: UniviaPro-regular;
  }

  .neo-vsr-background-logo {
    display: none;
  }

  .neo-vsr-container {
    padding: 16px;
  }

  .neo_vsr_accordion_active {
    background-image: url(/assets/vorsorgerechner-assets/img/up.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    padding-right: 30px;
    color: #00254d;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    padding: 12px 16px 12px 16px;
    border: 1px solid #D9DADB;
    border-left: 5px solid #7DCBB8;
    border-bottom: 0px;
    border-radius: 4px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    font-family: UniviaPro-Bold;
  }

  .mdc-layout-grid__cell .neo-vsr-kinder.neo-vsr-kinder {
    width: 100%;
    margin-top: 8px;
  }
}