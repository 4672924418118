@import "../partials/neo.partial.main";
@import "@material/layout-grid/mdc-layout-grid";


// NEO VGR starts here
/// need to be revised START !!!
.mat-mdc-radio-group.neo-gender-select {
  .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle 
  {
    border-color: $neo-color-secondary;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: $neo-color-secondary;

  }
  .mat-mdc-radio-button.neo-vgr-input-margin.mat-accent.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-color: $neo-color-secondary;
  }

  .mat-mdc-radio-button.neo-gender-select-hidden.mat-accent.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle,
  .mat-mdc-radio-button.neo-vgr-input-margin.mat-accent.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
    background-color: $neo-color-secondary;
    border-color: $neo-color-secondary;
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: $neo-color-secondary;
    --mdc-radio-selected-hover-icon-color: $neo-color-secondary;
    --mdc-radio-selected-icon-color: $neo-color-secondary;
    --mdc-radio-selected-pressed-icon-color: $neo-color-secondary;
    --mat-mdc-radio-ripple-color: $neo-color-secondary;
    --mat-mdc-radio-checked-ripple-color: $neo-color-secondary;
    }
}
neo-input-radio-group {
  mat-radio-group {
    .mat-radio-label {
      white-space: initial;
    }
    .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle 
    {
      border-color: $neo-color-secondary;
    }

    .mat-mdc-radio-button.neo-vgr-input-margin.mat-accent.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle 
    {
      border-color: $neo-color-secondary;
    }
    
    .mat-mdc-radio-button.neo-vgr-input-margin.mat-accent.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle
    {
      background-color: $neo-color-secondary;
      border-color: $neo-color-secondary;
    }

    .mat-mdc-radio-button.neo-vgr-input-margin.mat-accent {
    --mdc-radio-selected-focus-icon-color: $neo-color-secondary;
    --mdc-radio-selected-hover-icon-color: $neo-color-secondary;
    --mdc-radio-selected-icon-color: $neo-color-secondary;
    --mdc-radio-selected-pressed-icon-color: $neo-color-secondary;
    --mat-mdc-radio-ripple-color: $neo-color-secondary;
    --mat-mdc-radio-checked-ripple-color: $neo-color-secondary;
    }
  }
}

neo-input-checkbox{
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $neo-color-secondary ;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: $neo-color-secondary;
  }
  .mat-mdc-checkbox{
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout{
      white-space: initial;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
      border-bottom-right-radius: $neo-border-radius-m;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-background, .mat-checkbox-frame {
      border-bottom-right-radius: $neo-border-radius-m;
    }
  }
}

.neo-vvg-dialog {
  position: absolute !important;
  .mat-mdc-dialog-container {
    .neo-vvg-dialog-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    border-bottom-right-radius: $neo-border-radius-m;
    background-color: $neo-color-grey-primary;
  }
}

/// need to be revised END !!!
