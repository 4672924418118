.neo-base-wrapper {
  .neo-x-entry-search {
    width: 100%;
    &-wrapper {
      background: $neo-color-gery-small-search;
      &-title {
        display: flex;
        justify-content: space-between;
        color: $neo-color-primary;
      }
    
      input {
        height: 50px;
        border: 1px solid $neo-color-primary;
        color: black;
      }
    }
  }
} 
.neo-home-base-wrapper {
  .neo-x-entry-search {
    width: 100%;
    &-wrapper {
      background: $neo-color-gery-small-search;
      &-title {
        display: flex;
        justify-content: space-between;
        color: $neo-color-primary;
      }
    
      input {
        height: 50px;
        border: 1px solid $neo-color-primary;
        color: black;
      }
    }
  }
} 
.align-items-center{
  align-items: center;
}
.display-inline-radio{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper{
  display: none;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label{
  margin-top: 11px;
  margin-left: 5px;
}
neo-input-integer{
  margin-right: 10px;
  ::placeholder{
    color: #00254D;
  }
}
mat-form-field.neo-vsr-mat-form-field .mat-mdc-form-field-infix{
  text-align: right;
  margin-right: 6px;
  position: unset;
  line-height: normal;
}


.neo-vsr-dropdown .mat-select-trigger {
  height: 100%;
}

.neo-vsr-dropdown .mat-select-trigger .mat-select-value {
  text-align: right;
}

mat-form-field.neo-vsr-mat-form-field .mat-mdc-form-field-wrapper .mat-form-field-underline {
  height: 0px;
}


.neo-vsr-contact-form .neo-helper-grid-gap-10 .mdc-layout-grid__cell .neo-helper-grid-gap-10 .neo-contact-form-inputs{
  display: grid;
}

// .neo-vsr-contact-form .neo-helper-grid-gap-10 div.mdc-layout-grid__cell .neo-helper-grid-gap-10 .neo-contact-form-inputs neo-input-text .neo-vgr-input-margin {
//   margin: 10px 10px 0px 0px;
// }

// .neo-vsr-contact-form .neo-helper-grid-gap-10 div.mdc-layout-grid__cell .neo-helper-grid-gap-10 .neo-contact-form-inputs neo-input-completer .neo-vgr-input-margin {
//   margin: 10px 10px 0px 0px;
// }