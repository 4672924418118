/* You can add global styles to this file, and also import other style files */
@import "partials/neo.partial.main";
@import "layout/neo.layout.main";
@import "platform/neo.platform.main";
@import "components/neo.components.main";
@import "products/neo.products.main";
@import "services/neo.services.main";



