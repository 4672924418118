@import "../partials/neo.partial.main";

// buttons
.neo {
  &-btn {
    &-input {
      min-width: 80px;
      outline: 0;
      line-height: 38px;
      padding: 0 10px;
      border: none;
      border-radius: $neo-border-radius-s;
      cursor: pointer;

      &-frameless {
        margin: 0;
      }
      &-autosize {
        width: auto;
      }
      &-start {
        border-radius: $neo-border-radius-s $neo-border-radius-s $neo-base-radius $neo-border-radius-s;
      }

      &-end {
        border-radius: $neo-border-radius-s $neo-border-radius-s $neo-border-radius-l $neo-border-radius-s;
      }

      &-large {
        line-height: 48px;
        padding: 0 22px;
      }

      &-primary {
        color: $neo-color-fg;
        background-color: $neo-color-primary;

        &:hover {
          background: $neo-color-grey-primary;
          color: $neo-color-fg;
        }
      }
      &-navigation {
        width: 100%;
      }

      &-secondary {
        background-color: $neo-color-grey-secondary;

        &:hover {
          background: $neo-color-secondary;
          color: $neo-color-primary;
        }
      }

      &-group {
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-button-toggle-group, &.mat-button-toggle-standalone {
          box-shadow: none;
          background-color: transparent;
          flex-wrap: wrap;
          border: none;
          .neo-btn-input-primary {
            color: $neo-color-primary;
            background-color: $neo-color-grey-secondary;

            &:hover {
              background: $neo-color-primary;
              color: $neo-color-fg;
              /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
              .mat-button-toggle-label-content {
                color: $neo-color-fg;
              }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &.mat-button-toggle-checked {
              background: $neo-color-primary;
              /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
              .mat-button-toggle-label-content {
                color: $neo-color-fg;
              }
            }
          }
          /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
          .mat-button-toggle-button {
            cursor: pointer;
            width: 100%;
          }

          .neo-btn-input-secondary {
            background-color: $neo-color-grey-secondary;

            &:hover {
              background: $neo-color-secondary;
              color: $neo-color-primary;
              /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
              .mat-button-toggle-label-content {
                color: $neo-color-primary;
              }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &.mat-button-toggle-checked {
              background: $neo-color-secondary;
              /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
              .mat-button-toggle-label-content {
                color: $neo-color-primary;
              }
            }
          }
        }
      }
    }

    // icon button
    &-icon {
      cursor: pointer;
      display: inline-flex;
      margin: 10px 0;

      mat-icon {
        margin: 0 0 0 5px;
      }

      &-start {
        mat-icon {
          margin: 0 5px 0 0;
        }
      }
      &-end {
        mat-icon {
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
