/* ------------------------------------------------- */
/* ==from https://codepen.io/jakob-e/pen/doMoML       */
/* ------------------------------------------------- */
//  Helper function to replace characters in a string
@use "sass:math";

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index +
            str-length($search)), $search, $replace);
  }
  @return $string;
}
@function svg-url($svg){
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(math(str-length($svg), $slice));
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode (may need a few extra replacements)
    //
    $chunk: str-replace($chunk,'"','\'');
    $chunk: str-replace($chunk,'<','%3C');
    $chunk: str-replace($chunk,'>','%3E');
    $chunk: str-replace($chunk,'&','%26');
    $chunk: str-replace($chunk,'#','%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}
/* custom mixin to replace a defined fill color with another one */
@function svg-url-with-replaced-fill($svg, $fill-to-replace, $new-fill) {
  $replaced-svg: str-replace($svg, $fill-to-replace, $new-fill);
  $replaced-svg-url: svg-url('#{$replaced-svg}');
  @return $replaced-svg-url;
}
/* ------------- */
/* ==end         */
/* ------------- */