/// Overrides don´t use IMPORTANT!!! ///
// form components
neo-input {
  &-text {
    width: 100%;
  }

  // &-completer {
  //   width: 100%;
  // }
}


.swiper-vgr-container {
  margin-top: 50px;

  height: 260px;
}

.swiper-el {
  margin-top: 50px;

  .swiper.s-wrapper {
    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }
  }

}

.swiper-pagination {
  margin-bottom: -10px;
}

.bmi-tools-swiper {
  overflow: hidden;
  min-width: 200px;
}

.neo-grid-flex .bmi-tools-swiper .neo-grid-component {
  max-width: none;
  min-height: 205px;
}

.neo-grid-flex .bmi-tools-swiper .neo-grid-component .neo-grid-component-wrapper-icon {
  display: none;
}


@media (min-width: 1204px) {
  .swiper-el .s-wrapper .swiper-pagination {
    display: none;
  }
}

@media screen and (max-width: 959px) and (min-width: 842px) {
  .swiper-el .s-wrapper .swiper-pagination {
    display: none;
  }
}

.neo-grid-component {
  mat-icon {
    display: none;
  }

  &--color-krankenkassenvergleich {
    max-width: none;
    border: 1px solid #69C0BB;
    border-radius: 4px 4px 24px 4px;
    background-color: #69C0BB;

    h3,
    p {
      color: white
    }

    overflow: hidden;

    mat-icon {
      display: none;
    }

  }

  &--color-vorsorgerechner {
    border: 1px solid #CBBB9E;
    border-radius: 4px 4px 24px 4px;
    background-color: #CBBB9E;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }

  }

  &--color-bmi {
    border: 1px solid #A781A9;
    border-radius: 4px 4px 24px 4px;
    background-color: #A781A9;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }
  }

  &--color-hypothekenrechner {
    border: 1px solid #777777;
    border-radius: 4px 4px 24px 4px;
    background-color: #777777;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }

  }

  &--color-steuerrechner {
    border: 1px solid #F9B200;
    border-radius: 4px 4px 24px 4px;

    h3,
    p {
      color: white
    }

    background-color: #F9B200;

    mat-icon {
      display: none;
    }

  }

  &--color-risikomap {
    border: 1px solid #EB6A31;
    border-radius: 4px 4px 24px 4px;
    background-color: #EB6A31;

    h3,
    p {
      color: white
    }

    mat-icon {
      display: none;
    }

  }
}

.neo {

  &-vgr-wrapper,
  &-site-wrapper {

    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .neo-vgr-input-field,
    .mat-select-trigger {
      line-height: 1.6rem;
    }

    .neo-vgr-input-field,
    .neo-vgr-input-combobox {
      width: 100%;
      line-height: 1.6rem;
    }

    .neo-vgr-input-field {
      height: 45px;
      box-sizing: border-box;
      padding: $neo-input-text-padding;
      background: #F7F7F7;
      border: none;
      height: 50px;
      border-radius: $neo-border-radius-xs $neo-border-radius-xs $neo-border-radius-l $neo-border-radius-xs;
      min-width: $neo-input-width;
      outline: none;

      &.neo-vgr-input-combobox-select {
        padding-bottom: 0; // not final size not identical to normal inputs
      }
    }

    .neo-vgr-input-field-text {
      color: $neo-color-primary;
    }

    .neo-vgr-input-margin {
      margin: 0px 10px 0 0;
      font-size: 16px;
      font-family: UniviaPro-Regular;
    }

    .mat-form-field-infix {
      width: auto;
    }

    .neo-vgr-suchmaske div.neo-vgr-input-margin {
      padding-bottom: 10px;
    }

    .neo-input-datepicker {
      .mat-form-field-appearance-legacy {

        .mat-form-field-infix,
        .mat-form-field-wrapper {
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              line-height: 1.6em;
              padding: 5px 0 0 5px;
            }
          }

          padding: 0;
          border: none;
          display: flex;
        }
      }

      .mat-form-field-suffix {
        position: absolute;
        right: 15px;
        top: 12px;
      }

      .mat-form-field-underline,
      .mat-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  &-site-wrapper {
    .neo-vgr-wrapper {
      padding: 0;
    }
  }

  &-vgr-wrapper {
    padding: 20px 40px 30px 0px;
  }
}

neo-input-completer {
  width: 100%;
}