@import "neo.partial.colors";

// config
$debug: false !default;

// typo
$neo-font-family: NeoFont, Arial, sans-serif !default;
$neo-font-base-size: 16px !default;

$neo-headline1-font-size: 1.6rem !default;
$neo-headline2-font-size: 1.4rem !default;
$neo-headline3-font-size: 1.2rem !default;
$neo-headline4-font-size: 1.0rem !default;
$neo-headline5-font-size: 1.0rem !default;
$neo-headline6-font-size: 1.0rem !default;
$neo-headline1-color: $neo-color-primary;
$neo-headline2-color: $neo-color-primary;
$neo-headline3-color: $neo-color-primary;
$neo-headline4-color: $neo-color-primary;
$neo-headline5-color: $neo-color-primary;
$neo-headline6-color: $neo-color-primary;

$neo-font-size-12pt: 1rem !default;
$neo-font-size-14pt: 1.2rem !default;
$neo-font-size-16pt: 1.4rem !default;
$neo-font-size-20pt: 1.6rem !default;
$neo-font-size-24pt: 2rem !default;
$neo-font-size-30pt: 2.45rem !default;
$neo-font-size-35pt: 2.1875rem !default;
$neo-font-size-48pt: 4rem !default;

$neo-size-normal: 18px !default;
$neo-size-large: 24px !default;
$neo-size-small: 12px !default;
$neo-size-very-large: 32px !default;

$neo-input-border: 2px solid !default;

$neo-base-radius: 4px;
$neo-border-radius-xs: $neo-base-radius * 0.5 !default;
$neo-border-radius-s: $neo-base-radius !default;
$neo-border-radius-m: $neo-base-radius * 2 !default;
$neo-border-radius-l: $neo-base-radius * 3 !default;
$neo-border-radius-xl: $neo-base-radius * 6 !default;
$neo-border-radius-xxl: $neo-base-radius * 9 !default;

$neo-input-text-padding: 1px 5px !default;

$neo-input-width: 150px !default;

// responsive
$neo-desktop-max: 1920px;
$neo-tablet-max: 959px;
$neo-phone-max: 599px;
$neo-header-height-l: 120px !default;
$neo-header-height-m: 80px !default;
$neo-header-height-s: 60px !default;
$neo-logo-width-l: 100px;
$neo-logo-width-m: 60px;
$neo-logo-width-s: 40px;
$neo-header-menu-main-overlay-width-l: 95% !default;
$neo-header-menu-main-overlay-width-m: 50% !default;
$neo-header-menu-main-overlay-width-s: 80% !default;

// override material grid
$mdc-layout-grid-breakpoints: (
        desktop: $neo-tablet-max + 1,
        tablet: $neo-phone-max + 1,
        phone: 0px
);

$mdc-layout-grid-columns: (
        desktop: 12,
        tablet: 12,
        phone: 12
);

//// website ////
$neo-site-wrapper-max-width: $neo-desktop-max !default;
$neo-site-outline-color: $neo-color-grey-secondary;
$neo-site-base-space: 24px;

// header
$neo-header-wrapper-spacing: 0 10px !default;
$neo-header-menu-main-font-weight: bold !default;
$neo-header-menu-main-font-size: 1.2rem !default;
$neo-header-menu-main-overlay-headline-font-size: $neo-header-menu-main-font-size !default;
$neo-header-menu-main-overlay-headline-font-weight: 700 !default;
$neo-header-menu-main-overlay-link-font-size: 1.0rem !default;
$neo-header-menu-main-overlay-link-font-weight: 300 !default;

$neo-base-picture-height: 450px !default;
$neo-base-picture-height-responsive: 250px !default;

// footer
$neo-footer-headline-color: $neo-color-fg !default;
$neo-footer-color: transparentize($neo-footer-headline-color, 0.5) !default;
$neo-footer-bg-color: $neo-color-primary !default;

$neo-width: 100% !default;
$neo-padding-horizontal: 100px !default;
$neo-padding-vertical: 35px !default;
$neo-padding-vertical-small: 10px !default;

//// components ////
// square box
$neo-square-width: 210px !default;
$neo-square-min-height: 110px !default;

$neo-square-padding: 15px !default;
$neo-square-arrow-size: 16px !default;

// tiny square box
$neo-square-tiny-min-width: 150px !default;
$neo-square-tiny-min-height: 150px !default;

$neo-square-tiny-padding: 0px !default;
$neo-square-tiny-arrow-size: 0px !default;
$neo-square-tiny-offset: -2% !default;

