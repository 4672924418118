$neo-color-fg: rgba(255, 255, 255, 1.0) !default;

$neo-color-grey-primary: #D9DADB !default;
$neo-color-grey-secondary: #ECEBEA !default;
$neo-color-gery-small-search: #F7F7F7 !default;

$neo-color-primary: #00254D !default;
$neo-color-secondary: #70C9E6 !default;

$neo-color-neosana: #69C0BB !default;
$neo-color-neodrive: #BCD056 !default;
$neo-color-neokids: #F9B200 !default;
$neo-color-neoacademy: #EB6A31 !default;
$neo-color-neoprotect: #CBBB9E !default;
$neo-color-neofiducia: #A781A9 !default;

$neo-color-gold: #FFD700 !default;
$neo-color-silver: #C0C0C0 !default;
$neo-color-bronze: #CD7F32 !default;

$neo-color-error-primary: rgba(113, 0, 0, 1.0) !default;
$neo-color-error-secondary: #E3CCCC !default;
$neo-color-warning-primary: $neo-color-primary !default;
$neo-color-warning-secondary: $neo-color-grey-secondary !default;

$neo-color-vgr-primary: #69C0BB !default;

$colors: (
        neo-color-fg: $neo-color-fg,
        neo-color-grey-primary: $neo-color-grey-primary,
        neo-color-grey-secondary: $neo-color-grey-secondary,
        neo-color-primary: $neo-color-primary,
        neo-color-secondary: $neo-color-secondary,
        neo-color-neodrive: $neo-color-neodrive,
        neo-color-neosana: $neo-color-neosana,
        neo-color-neokids: $neo-color-neokids,
        neo-color-neoacademy: $neo-color-neoacademy,
        neo-color-neoprotect: $neo-color-neoprotect,
        neo-color-neofiducia: $neo-color-neofiducia,
);