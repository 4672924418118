@import "../partials/neo.partial.variables";
.d-flex {
  display: flex;
}
.tick{
  position: relative;
  top: 5px;
  padding-right: 5px;
  margin-left: 20px;
  
}
.neo-titleschritt {
  margin-top: 15px;
}
.neo-mat-card .mat-mdc-card-header-text {
  width: 100%;
  height:44px;
  margin: 0px;
}
mat-card.neo-mat-card .mat-mdc-card-actions {
  padding-bottom: 20px;
}
mat-card.neo-mat-card .mat-mdc-card-actions:last-child  {
  padding:20px;
}

.neo {
  &-debug {
    box-sizing: border-box;
    @if $debug == false {
      display: none;
    }
    width: 100%;
    border: 1px solid $neo-color-grey-primary;
    background-color: $neo-color-grey-secondary;
    * {
      color: $neo-color-fg;
      font-size: 0.8rem;
    }
    &-wrapper {
      padding: 5px 10px;
    }
  }
  &-hide {
    display: none;
  }
  &-show {
    display: inherit;
  }
  &-version {
    float: right;
  }
}
.neo-phone-h3{
  cursor: pointer;
  font-family: $neo-font-family;
  color:$neo-color-primary ;
  font-weight: bold;
}
/* desktop and large tablets */
@media only screen and (min-width: ($neo-tablet-max + 1)) {
  .neo {
    &-debug {
      border-color: #227a18;
      background: transparentize(#227a18, 0.5);
    }
    &-hide {
      &-l {
        display: none;
      }
    }
    &-show {
      &-l {
        display: inherit;
      }
    }
  }
}

/* tablet and large phones */
@media only screen and (max-width: $neo-tablet-max) {
  .neo {
    &-debug {
      border-color: #c15300;
      background: transparentize(#c15300, 0.5);
    }
    &-hide {
      &-m {
        display: none;
      }
    }
    &-show {
      &-m {
        display: inherit;
      }
    }
  }
}

/* phones */
@media only screen and (max-width: $neo-phone-max) {
  .neo {
    &-debug {
      border-color: #9b0c0e;
      background: transparentize(#9b0c0e, 0.5);
    }
    &-hide {
      &-s {
        display: none;
      }
    }
    &-show {
      &-s {
        display: inherit;
      }
    }
  }
.tick{
  margin-left: 0;
  padding-right: 0px;
  font-size: 22px;
}
.neo-titleschritt {
  margin-top: 15px;
}
.neo-mat-card .mat-mdc-card-header-text {
  width: 100%;
  height:44px;
  margin: 0px;
}
mat-card.neo-mat-card .mat-mdc-card-actions {
  padding-bottom: 20px;
}
mat-card.neo-mat-card .mat-mdc-card-actions:last-child  {
  padding:20px;
}
}