/// Overrides don´t use IMPORTANT!!! ///
// form components
neo-input {
  &-text {
    width: 100%;
  }
  // &-completer {
  //   width: 100%;
  // }
}

.neo {
  &-vgr-wrapper, &-site-wrapper {
    .neo-vgr-input-field, .mat-select-trigger {
      line-height: 2.6rem;
    }

    .neo-vgr-input-field, .neo-vgr-input-combobox {
      width: 100%;
      line-height: 1.6rem;
    }

    .neo-vgr-input-field {
      height: 45px;
      box-sizing: border-box;
      padding: $neo-input-text-padding;
      background: #F7F7F7;
      border: none;
      height: 50px;
      border-radius: $neo-border-radius-xs $neo-border-radius-xs $neo-border-radius-l $neo-border-radius-xs;
      min-width: $neo-input-width;
      outline: none;
      &.neo-vgr-input-combobox-select {
        padding-bottom: 0; // not final size not identical to normal inputs
      }
    }

    .neo-vgr-input-field-text {
      color: $neo-color-primary;
    }

    .neo-vgr-input-margin {
      margin: 10px 10px 0 0;
    }
   /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   mat-form-field .mat-form-field-infix {
      width: auto;
      position: unset ;
    }

    .neo-input-datepicker {
      .mat-form-field-appearance-legacy {
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix, .mat-form-field-wrapper {
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label-wrapper {
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-label {
              line-height: 1.6em;
              padding: 5px 0 0 5px;
            }
          }
          padding: 0;
          border: none;
          display: flex;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-suffix {
        position: absolute;
        right: 15px;
        top: 12px;
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline, .mat-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  &-site-wrapper {
    .neo-vgr-wrapper {
      padding: 0;
    }
  }

  &-vgr-wrapper {
    padding: 10px;
  }
}

