
.neo-sidebyside {
  background-color: $neo-color-primary;

  ul, p {
    margin: 10px 0 0 0;
  }

  &-wrapper {
  }

  &-content, &-image {
    &-wrapper {
    }
  }

  &-content-wrapper {
    padding: 5%;
  }

  &-image-wrapper {
    overflow: hidden;
    height: 100%;
    margin-top: 0; // only for IE 11
    margin-bottom: 0; // only for IE 11

    .neo-sidebyside-image {
      width: 100%;
      min-height: 100%;
      height: 100%; /*for IE*/
      display: block;
      background-position: 0 0;
      background-size: cover;
    }
  }

  &-content {
    &-text {
      * {
        color: $neo-color-fg;
      }
    }
    &-deeplink {
      font-weight: bold;
      font-size: 1.1rem;
      a {
        color: $neo-color-fg;
      }
    }

  }
}