@import "../partials/neo.partial.main";

.neo {

  // container
  &-container {
    &-primary {
      background-color: $neo-color-primary;
    }
    &-secondary {
      background-color: $neo-color-grey-secondary;
    }
  }

  // messages
  &-message {
    position: relative;
    &-fixed {
      position: fixed;
      z-index: 1000;
      max-width: $neo-site-wrapper-max-width;

      &-top {
        top: 0;
      }
      &-bottom {
        bottom: 5px;
      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &-wrapper {
      padding: 10px 40px 10px 10px;
    }

    &-error, &-warning {
      border-radius: 2px;
      border: 1px solid $neo-color-grey-primary;
      text-align: center;
      font-size: 0.9rem;

      &-tooltip {
        &.mat-mdc-tooltip {
          border-radius: 2px;
          font-family: $neo-font-family;
          font-size: 0.6rem;
        }
      }
    }

    $message-color-primary: $neo-color-error-primary;
    $message-color-secondary: $neo-color-error-secondary;
    &-error {
      color: $message-color-primary;
      border-color: $message-color-primary;
      * {
        color: $message-color-primary;
      }
      background: $message-color-secondary;

      &-input {
        &.neo-vgr-input-field {
          &.neo-vgr-input-field-text {
            border: 1px solid $message-color-primary;
            background-color: $message-color-secondary;
            color: $message-color-primary;
          }
        }
      }

      &-tooltip {
        &.mat-mdc-tooltip {
          background: $message-color-secondary;
          color: $message-color-primary;
        }
      }
    }

    $message-color-primary: $neo-color-warning-primary;
    $message-color-secondary: $neo-color-warning-secondary;
    &-warning {
      color: $message-color-primary;
      border-color: $message-color-primary;
      * {
        color: $message-color-primary;
      }
      background: $message-color-secondary;

      &-input {
        border: 1px solid $message-color-primary;
        color: $message-color-primary;
      }

      &-tooltip {
        &.mat-mdc-tooltip {
          background: $message-color-secondary;
          color: $message-color-primary;
        }
      }
    }
  }

  // horizontal line
  &-horizontal-line {
    border-top: 1px solid $neo-color-grey-primary;
    border-bottom: none;
    border-right: none;
    border-left: none;
    color: transparent;

    &-primary {
      border-top-color: $neo-color-primary;
    }
    &-secondary {
      border-top-color: $neo-color-grey-secondary;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;

  &.neo-vgr-input-field {
    &.neo-vgr-input-field-text {
      border: 1px solid $neo-color-primary;
      -webkit-text-fill-color: $neo-color-primary;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    $message-color-primary: $neo-color-error-primary;
    $message-color-secondary: $neo-color-error-secondary;
    &.neo-message-error-input {
      border: 1px solid $message-color-primary;
      -webkit-text-fill-color: $message-color-primary;
      -webkit-box-shadow: 0 0 0px 1000px $message-color-secondary inset;
    }
  }
}

//Loading Overlay Animation
/* Center Animation */
.neo-loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.neo-loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.neo-loading-animation {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px;
}

.neo-loading-animation div {
  display: inline-block;
  position: absolute;
  width: 26px;
  background: $neo-color-secondary;
  border-radius: $neo-border-radius-xs $neo-border-radius-xs $neo-border-radius-m $neo-border-radius-xs;
  animation: neo-loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.neo-loading-animation div:nth-child(1) {
  left: 0px;
  animation-delay: -0.24s;
}

.neo-loading-animation div:nth-child(2) {
  left: 39px;
  animation-delay: -0.12s;
}

.neo-loading-animation div:nth-child(3) {
  left: 78px;
  animation-delay: 0;
}

@keyframes neo-loading-animation {
  0% {
    top: 6px;
    height: 52px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}