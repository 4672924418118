@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext");
//import the icon set
//Icon Cheat Sheet https://cdn.materialdesignicons.com/2.8.94/
// @import "../../../node_modules/@mdi/font/css/materialdesignicons.min.css";

@font-face {
  font-family: "NeoFont";
  src: url("/assets/fonts/univiapro-regular-webfont.woff2") format("woff2"),
    url("/assets/fonts/univiapro-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeoFont";
  src: url("/assets/fonts/univiapro-light-webfont.woff2") format("woff2"),
    url("/assets/fonts/univiapro-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NeoFont";
  src: url("/assets/fonts/univiapro-bold-webfont.woff2") format("woff2"),
    url("/assets/fonts/univiapro-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

html,
body * {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
}

h1 {
  color: $neo-headline1-color;
  font-weight: 700;
  font-size: $neo-headline1-font-size;
}

h2 {
  color: $neo-headline2-color;
  font-weight: 700;
  font-size: $neo-headline2-font-size;
}

h3 {
  color: $neo-headline3-color;
  font-weight: 700;
  font-size: $neo-headline3-font-size
}

h4 {
  color: $neo-headline4-color;
  font-weight: 700;
  font-size: $neo-headline4-font-size
}

h5 {
  color: $neo-headline5-color;
  font-size: $neo-headline5-font-size;
}

h6 {
  color: $neo-headline6-color;
  font-size: $neo-headline6-font-size;
}