$prefix: "--"; //Prefix string for custom CSS properties

//Merges a variable name with $prefix
@function custom-property-name($name) {
  @return $prefix + $name;
}

// Defines a custom property
@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: $value;
}

body {
  @each $name, $value in $colors {
    @include define-custom-property($name, $value);
  }
  // Append SASS variables which are desired to be accesible:
}