.neo-base-flex-wrapper {
  neo-swiper-icon {
    .swiper.s-wrapper {
      .swiper-button-prev{
        display: none;
      }
      .swiper-button-next{
        display: none;
      }
      padding: 0 0 40px 0;
      .swiper-wrapper .swiper-slide {
        margin: 0px 25px;
        width: auto;
        height: auto;
        overflow: hidden;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-wrapper .swiper-slide-active {
        width: auto;
        height: auto;
        margin: 0px 25px;
        overflow: hidden;
      }
      .swiper-pagination {
        .swiper-pagination-handle {
          .swiper-pagination-bullet {
            background-color: $neo-color-primary;
          }
          .swiper-pagination-bullet.swiper-pagination-bullet-first,
          .swiper-pagination-bullet.swiper-pagination-bullet-last,
          .swiper-pagination-bullet.swiper-pagination-bullet-middle {
            border: 0px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}
