@import "../partials/neo.partial.variables";
/* tablet and large phones */
@media only screen and (max-width: $neo-tablet-max) and (min-width: $neo-phone-max) {
  .neo {
    &-header {
      max-height: $neo-header-height-m;
      border-bottom: 1px solid $neo-color-grey-secondary;

      &-logo {
        &-wrapper {
          max-width: $neo-logo-width-m;
        }
      }
    }

    &-message-content {
      padding-top: 30px;
    }

    &-footer {
      h2 {
        display: inline-block;
      }

      &-address {
        white-space: normal;
        align-self: center;
      }

      &-imprint {
        align-self: center;
      }
    }

    &-site-wrapper {
      border: none;
    }

    &-content {
      &-wrapper {
        margin: 0 auto;
        padding: 2%;
      }
    }

    &-site-wrapper {
      .neo-vgr-wrapper {
        padding: 0;
      }
    }

    &-vgr-wrapper {
      padding: 2%;
    }

    /*** Modal Dialog Info Button ***/
    &-vvg-dialog {
      width: 75%;
    }

    /** Components **/
    /**** Menu overlay ****/
    &-menu-main-overlay {
      background-color: rgba(0, 0, 0, 0.0);
      /* do not delete, used transparent overlay to toggle menu */

      &-content {
        left: 0;
        top: $neo-header-height-m;
        width: $neo-header-menu-main-overlay-width-m;
        height: 100vh;
        border-right: 1px solid $neo-color-grey-secondary;
        overflow: scroll;

        &:after {
          display: none;
        }

        &-wrapper {
          padding: 10px 20px;
        }

        &-column {
          border-right: none;
          padding: 0;
          grid-column-end: span 12;
        }
      }
    }

    /**** base page ****/
    &-base {
      &-picture {
        &-text {
          margin: 20px auto 20px auto;
          padding: 0 20px;
          text-align: center;
        }

        &-x-entry {
          margin: 0 auto 30px auto;
        }
      }
    }

    /**** home base page ****/
    &-home-base {
      &-picture {
        &-wrapper {
          height: 430px;
        }

        &-text {
          text-align: center;
          justify-self: center;
          width: 50%;
        }

        &-x-entry {
          margin: 0 auto 30px auto;
        }
      }

      .picture-text-content {
        text-shadow: 0 0 black;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 35px;
      }
    }

    /**** Side by side ****/
    &-sidebyside {
      &-image-wrapper {
        min-height: 400px;
      }
    }

    /**** Donut Graph ****/
    &-donut-grid {
      &-inner {
        max-width: 150px;
        margin: 0 auto;
      }
    }

    /**** Person Search ****/
    &-container {
      &-rounded {
        border-radius: $neo-border-radius-m $neo-border-radius-m $neo-border-radius-xxl $neo-border-radius-m;
      }
    }

    &-recommendation-info-position {
      position: absolute;
      top: 25%;
    }

    /*** vsr ***/

    &-vsr-grid {
      height: 100%;

    }






    &-vsr {
      &-child-table {
        display: flex;
        flex-wrap: wrap;

        &-trow {
          padding-right: 10px;
          padding-bottom: 20px;
        }


      }

      &-schritt3 {
        border: 1px solid #d9dadb;
        border-left: 5px solid #7DCBB8;
        border-top: 0px;
        padding: 20px 16px;
        border-radius: 0px 0px 4px 4px;
      }


      &-doughnut-chart-canvas {
        height: 200px;
      }

      &-doughnut-chart-td {
        vertical-align: bottom;
      }

      &-doughnut-chart-des {
        margin: 16px 0px;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        display: flex;
        align-content: center;
        justify-content: center;
        color: #00254d;
        line-height: 20px;
      }

      &-chart-des {
        color: #00254d;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        border-right: 1px solid #D9DADB;
        border-left: 5px solid #7DCBB8;
        padding: 12px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        align-content: center;
        text-align: center;
      }

      &-doughnut-chart-header-des {
        color: #00254d;
        font-size: 14px;
        font-family: 'UniviaPro-Regular';
        // border-right: 1px solid #D9DADB;
        // border-left: 5px solid #7DCBB8;
        padding: 12px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        align-content: center;
        text-align: center;
      }

      &-doughnut-chart-tr {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }

      &-doughnutchart {
        overflow: hidden;
      }

      &-showtwobuttons {
        &-toggle {
          &-txt {
            margin: 3px;
            margin-right: 15px;
          }

          &-click {
            outline: none;
            background: white;
            border: none;
          }

          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-around;
          flex-wrap: wrap;
          flex-direction: row;
        }

      }

      &-content-step {
        border: 1px solid var(--unnamed-color-d9dadb);
        border: 1px solid #D9DADB;
        border-radius: 0px 0px 4px 4px;
        border-top: 0px;
        opacity: 1;
        border-left: 5px solid #7DCBB8;
        padding: 0px 30px;
      }


      &-alter {
        width: 100%;
        padding: 21px 10px 0px 0px;


      }

      &-familienstand {
        width: 100%;
        padding: 19px 0px 0px 10px;

      }

      &-schritt-partner {
        display: flex;
        width: 100%;
        flex-direction: row;
      }

      &-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-td {
          top: 454px;
          left: 640px;
          height: 16px;
          text-align: left;
          letter-spacing: 0px;
          color: #02254D;
          opacity: 1;
        }

        &-add {
          height: 93px;
          padding: 4px;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
        }
      }

      &-partner {
        width: 100%;
        padding: 10px;
        padding-top: 20px;

        &-alter {
          display: flex;
          height: 50px;
          padding: 10px 0px;
          align-items: flex-end;
        }
      }

      &-jahresinkommen {
        width: 100%;
      }

      &-jahreseinkommen-title {
        &-label {
          width: 250px
        }

        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding:0px 0px 10px 0px;
        align-items: flex-end;
        justify-content: space-between;
      }

      &-schritt1 {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }


    &-vsr-flex {
      display: flex;
    }



    &-vsr-right::-webkit-scrollbar {
      width: 0;
    }


    &-recommendation-info {
      font-size: 21px;
      vertical-align: bottom;
      padding-left: 8px;
      top: unset;
      cursor: pointer;
    }

    &-vsr-dropdown-select-trigger {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-vsr-kinder {
      margin-top: 8px;

      &-table {
        display: flex;
        justify-content: flex-start;

        &-trow {
          display: flex;
          flex-direction: column;
          align-content: center;
        }
      }

      &-text {
        top: 421px;
        left: 640px;
        width: 63px;
        height: 20px;
        text-align: left;
        font: normal normal bold 20px/17px Univia Pro;
        letter-spacing: 0px;
        color: #00254D;
        opacity: 1;
      }

      &-alter {
        width: 100%;

        &-td {
          width: 100%;
          display: flex;
        }
      }
    }






    &-vsr-left {
      // background: url(../../../assets/vorsorgerechner-assets/img/neo-half-o-vertical-2.svg);
      // background-repeat: no-repeat;
      // background-position-y: center;
      // background-position-x: left;
      // background-size: 143px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      &-title {
        font-size: 32px;
        font-family: 'UniviaPro-Bold';
        height: auto;
        color: var(--unnamed-color-cbbb9e);
        text-align: left;
        // font: normal normal medium 32px/44px Univia Pro;
        letter-spacing: 0px;
        color: #7DCBB8;
        opacity: 1;
      }

      &-subtitle {
        font-size: 16px;
        font-family: 'UniviaPro-Regular';
        height: auto;
        // letter-spacing: var(--unnamed-character-spacing-0-3);
        text-align: left;
        // font: normal normal normal 20px/31px Univia Pro;
        letter-spacing: 0.3px;
        color: #00254D;
        opacity: 1;
      }

      &-description {
        font-size: 16px;
        font-family: 'UniviaPro-Regular';
        height: auto;
        // letter-spacing: var(--unnamed-character-spacing-0-3);
        text-align: left;
        // font: normal normal normal 20px/31px Univia Pro;
        letter-spacing: 0.3px;
        color: #00254D;
        opacity: 1;
      }
    }




    /**** vgr ****/
    /*** stepper ***/
    &-stepper {
      .mat-horizontal-stepper {
        &-header {
          &-container {
            width: 70%;
          }
        }
      }
    }

    /*** sticky top position ***/
    &-tariff-detail-element-sticky-element {
      top: $neo-header-height-m !important;
    }

    /**** Neo Buttons ****/
    &-btn {
      &-input {
        margin: 10px 10px 10px 0;
        width: auto;

        &-group {
          width: auto;
        }

        &-longtext {
          width: 200px;
          min-width: 200px;
        }
      }
    }

    /*** medium search ***/
    &-search {
      &-medium {
        &-toggle {
          top: $neo-header-height-m + 25px;
        }

        &-wrapper {
          top: $neo-header-height-m + 25px;
          left: -300px;
        }
      }
    }

    /*** small search init height ***/
    &-x-entry-search-content-outer {
      height: auto;
    }

    /** neo about section **/
    &-about {
      &-div {
        background-color: #F0F9FC;
        height: auto;
        width: auto;
        padding: 24px;
      }

      &-headings {
        display: flex;
        align-content: flex-start;
        justify-content: space-between;
        margin-top: -1%;
        width: 50%;
        max-width: 450px;
      }

      &-paragraphs {
        display: flex;
        align-content: flex-start;
        justify-content: space-between;
        width: 100%;
        font-weight: 100;
      }
    }


    /*** News ***/
    &-news {
      padding: 0px;

      &-content {
        padding: 0px;

      }

      &-aktuelles-thema {
        font-size: 32px;

        &-tablet {
          display: contents;
          width: 100%;
          text-align: right;
        }
      }

      &-img-mehr-info {
        padding-left: 10px;
        padding-top: 5px;
        display: none;
      }

      &-link-mehr-info {
        display: flex;
        opacity: 0.7;
        font-weight: 500;
        width: max-content;
      }

      &-link-mehr-info:hover {
        color: #00254d;
        cursor: pointer;

        .neo-news-img-mehr-info {
          display: block;
          cursor: pointer;
        }
      }

      &-left-content {
        word-break: keep-all;

        &-img {
          height: 308px;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          background-position-y: top;
        }
      }

      &-img {
        width: 100%;
        height: 308px;
        opacity: 1;
      }

      &-img-mehr-info {
        padding-left: 10px;
        display: none;
      }
    }


    &-mehr-news {
      width: 100%;
      padding-left: 0px;
      margin-bottom: 60px;

      &-link {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        opacity: 0.8;
      }

      &-link:hover {
        width: 100%;
        display: flex;
        color: #00254d;
      }
    }

    &-mehr-news:hover {
      cursor: pointer;
      color: #00254d;
    }

    &-mehr-news:hover {
      .neo-news-img-mehr-info {
        display: block;
        cursor: pointer;
      }
    }
  }

  .neo-menu-x-button {

    margin-left: 16px;
    cursor: pointer;
    margin-top: 15px;
  }

  .menu-grid {
    display: block;
    margin-left: -40px;
  }

  li {
    width: 100%;
  }

  .mat-expansion-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 6px 0px 24px;
    border-radius: inherit;
  }

  .delang {
    margin-left: 100px;
  }

  .neo-number {
    margin: 8px 8px 8px -20px;
  }

  .neo-vsr-bg {
    display: none;
  }

  .neo-phone-h3 {
    font-size: 1.1rem;
  }

  .mat-expansion-panel-header {
    padding: 0px;
  }

  .menu-content-padding-top {
    padding-top: 1rem;
  }

  .neo-vsr-background-logo {
    display: none;
  }

  .neo-vsr-container {
    padding: 16px;
  }

  .neo-vsr-content-step .neo-vsr-schritt1 .neo-vsr-alter {
    width: 100%;
  }

  .neo-vsr-partner-grid .neo-vsr-schritt-partner .neo-vsr-partner {
    width: 100%;
  }

  .neo-vsr-grid .mdc-layout-grid__inner.neo-vsr-inner {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: flex-start;

  }

  .neo-vsr-showtwobuttons-toggle {
    display: flex;
    justify-content: flex-end;
  }

  .neo-vsr-doughnutchart .mdc-layout-grid .neo-vsr-doughnutchart-grid {
    display: flex;
    justify-content: center;
    border-top: 0px;
  }

  .neo-vsr-inner .mdc-layout-grid__cell--span-8-desktop.neo-vsr-right {
    width: 100%;
    padding: 0px;
  }

  .neo-vsr-right-contact .neo-vsr-right-contact-grid {
    padding: 0px;
  }

  .neo-vsr-right-contact-grid form .mdc-layout-grid__inner .neo-vgr-contact-form h2 {
    margin: 20px 0px;
  }

  .neo-vsr-right-contact-grid .mdc-layout-grid {
    padding: 0px;
  }

  // .neo-footer-jumbotron-list-div-main {
  //   padding-bottom: 36px;
  // }

  .mdc-list-item {
    padding: 0px 0px 25px 0px;
  }

  .neo-footer-left {
    width: 100%;
    padding: 36px 16px;
    display: flex;
    justify-content: space-between;
  }


  .neo-weniger-details {
    width: 50%;

  }

  .neo_vsr_accordion_schritt4_active {
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    padding-right: 30px;
    color: #00254d;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    padding: 12px 24px 12px 24px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    font-family: UniviaPro-Bold;
  }

  .neo_vsr_accordion_schritt4 {
    font-size: 20px;
    font-family: 'UniviaPro-Regular';
    background-color: red;
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    background-color: white;
    color: #00254d;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    padding: 12px 24px 12px 30px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    opacity: 1;
    height: 80px;
    font-family: UniviaPro-regular;

  }

  .neo-vsr-flex .neo-vsr-grid-layout .neo-vsr-grid-kinder {
    display: flex;
    width: 100%;
  }

  .neo-vsr-grid-kinder .neo-vsr-kinder .neo-vsr-child-table-trow {
    min-width: 25%;
    width: 25%;
  }

  .neo-vsr-left .neo-vsr-ticks .neo-vsr-span-ticks .neo-vsr-ticks-img {
    display: flex;
  }

  .left-text {
    align-self: center;
    padding: 18px 0px 23px 0px;
  }

  .neo-footer-agentur-finden {
    margin: 0px;
    width: auto;
    padding: 0px 65px;
  }

  .neo-footer-background {
    padding: 15px;
  }

  .neo-footer-wrapper {
    margin-top: auto;
    color: white;
  }

  .neo-footer-center-text {
    margin: 0px
  }

  .smallScreen {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
    align-content: flex-start;
  }

  .pramieBerechnen-btn {
    cursor: pointer;
    background: #00254D;
    color: white;
    width: 31%;
    height: 45px;
    line-height: 38px;
    padding: 1px 10px;
    border: none;
    border-radius: 4px 4px 12px 4px;
  }


  .neo_vsr_accordion {
    font-size: 20px;
    font-family: 'UniviaPro-Regular';
    background-image: url(/assets/vorsorgerechner-assets/img/down.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    background-color: white;
    color: #00254d;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    padding: 12px 24px 12px 30px;
    border: 1px solid #D9DADB;
    border-radius: 4px;
    opacity: 1;
    height: 80px;
    font-family: UniviaPro-regular;
  }

  .neo_vsr_accordion_active {
    background-image: url(/assets/vorsorgerechner-assets/img/up.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: content-box;
    background-position-y: center;
    padding-right: 30px;
    color: #00254d;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    padding: 12px 16px 12px 16px;
    border: 1px solid #D9DADB;
    border-left: 5px solid #7DCBB8;
    border-bottom: 0px;
    border-radius: 4px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    font-family: UniviaPro-Bold;
  }
}

@media only screen and (max-width: 1024px) {
  .pdf-page-padding {
    padding: 0% 0% 3% 3% !important;
  }
}

@media (max-width: 1130px) and (min-width: 959px) {
  .neo-footer-no-padding {
    width: 100%;
  }

  .neo-footer-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: unset;
    align-content: unset;
    align-items: unset;
  }

  .neo-footer-left .left-text {
    padding: 5px 0px 7px 0px;
    align-self: flex-start;
  }

  .neo-div-cont {
    padding: 30px 16px 16px 30px;
  }

  .neo-footer-no-padding {
    padding: 0px 16px;
  }

  .neo-phone-h3 h3 {
    margin: 0px;
  }
}