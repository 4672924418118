
.neo-page-wrapper {
  &.neo-page-product {
    &-#{$neo-product-name} {
      .neo-sidebyside {
        overflow: hidden;
        background-color: $neo-product-page-bg-color;
        &-wrapper {
          &-image {
            img {
              width: 100%;
              display: block;
            }
          }
          &-content {
            padding: 5%;
            &-text {
              * {
                color: $neo-product-page-color;
              }
            }
            &-deeplink {
              font-weight: bold;
              font-size: 1.1rem;
              color: $neo-product-page-deeplink-color;
            }
          }
        }
      }
    }
  }
}